.wrapper {
    .input-wrapper {
        position: relative;
        padding: 15px 0 0;
        margin-top: 15px;
        max-width: 100%;
        input {
            font-family: inherit;
            width: 100%;
            border: 0;
            border-bottom: 1px solid #ffffff;
            outline: 0;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            padding: 7px 0;
            background: transparent;
            transition: border-color 0.2s;
            &::placeholder {
                color: transparent;
            }
            &:required,
            &:invalid { 
                box-shadow:none; 
            }
            &:placeholder-shown ~ label {
                font-size: 16px;
                cursor: text;
                top: 20px;
            }
            &:focus {
                padding-bottom: 6px;  
                border-width: 1px;
                border-image: linear-gradient(to right, #ffffff,#000000);
                border-image-slice: 1;
                &~ label {
                    top: 0;
                    color: #000000; 
                    font-weight: bold;
                }
            }
        }
        &.with-value {
            label {
                top: 0;
                color: #000000;
                font-weight: bold;
            }
            input {
                padding-bottom: 6px;  
                border-width: 1px;
                border-image: linear-gradient(to right, #ffffff,#000000);
                border-image-slice: 1;
            }
        }
        label {
            position: absolute;
            top: 25px;
            display: block;
            transition: 0.2s;
            font-size: 16px;
            color: #ffffff;
            z-index: -1;
        }
    }
    .options-box {
        display: block;
        position: relative;
        margin-top: 15px;
        .color {
            font-family: inherit;
            width: 100%;
            border: 0;
            border-bottom: 1px solid #ffffff;
            outline: 0;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            padding: 7px 0;
            background: transparent;
            transition: border-color 0.2s;
        }
        .container-checkbox {
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            height: 25px;
            input {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;
                &:checked ~ .checkmark {
                    &:after {
                        display: block;
                        content: "";
                        position: absolute;
                        left: 9px;
                        top: 5px;
                        width: 5px;
                        height: 10px;
                        border: solid #000000;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #eee;
                cursor: pointer;
                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
        }
    }
}