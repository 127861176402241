* {
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: gray;
}

.container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 15px;
}

.row {
    margin-bottom: 20px;
    &:last-of-type {
        margin-bottom: 0;
    }
    &.colorPicker {
        @media( max-width: 992px ) {
            max-width: 300px;
        }
    }
    &.select {
        select {
            padding: 0 5px;
        }
    }
}

select {
    background-color: transparent;
    outline: none;
    border: 1px solid #ffffff;
    width: 100%;
    height: 40px;
    color: #ffffff;
    option {
        background-color: #ffffff;
        color: #000000
    }
}

.app {
    display: none;
    @media( min-width: 600px ) {
        display: block;
    }
}

.app-mobile {
    @media( min-width: 600px ) {
        display: none
    }
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
        text-align: center;
        color :#ffffff;
    }
}

.wrapper-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    .row-items {
        display: flex;
        width: 100%;
        @media( max-width: 992px ) {
            flex-direction: column;
        }
        .menu {
            max-width: 350px;
            min-width: 350px;
            margin-right: 30px;
            @media( max-width: 992px ) {
                max-width: 100%;
                min-width: 100%;
            }
        }
        .preview {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 800px;
            min-height: 500px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            border: 1px solid #000000;
            background-color: #ffffff;
            @media( max-width: 992px ) {
                margin-top: 15px;
                max-width: 100%;
                min-width: 100%;
            }
        }
    }
    .actions {
        margin-top: 15px;
    }
}

.btn {
    background-color: transparent;
    border: 1px solid #ffffff;
    text-transform: uppercase;
    color: #ffffff;
    padding: 10px 15px;
    cursor: pointer;
    outline: none;
}